<template>
    <div class="manualAddition animated fadeIn faster">
        <ar-dialog
            id          ="confirm-add-listing-modal"
            heading     ="Adding new listing"
            sub_heading ="Would you like to proceed with the given info?"
        >
            <template v-slot:buttons>
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined    ="true">
                    Cancel
                </ar-button>
                <ar-button @click="addListing()">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog
            id            ="whitelist-info-modal"
            heading       ="Whitelist Seller"
            heading_style ="color: #DC3C40"
            sub_heading   ="You are trying to change status a whitelisted seller."
            :has_footer   ="false"
            :has_button   ="false"
        >
            <div class="d-flex flex-column align-items-start text-left">
                <p class="text-muted text-bold">Name: <span>{{detected_whitelist.name}}</span></p>
                <p class="text-muted text-bold d-inline-block text-truncate" style="max-width: 100%" :title="detected_whitelist.url">URL: <span>{{detected_whitelist.url}}</span></p>
                <p class="text-muted text-bold">Site ID: <span>{{detected_whitelist.site_id}}</span></p>
                <p class="text-muted text-bold">Platform: <span>{{detected_whitelist.platforms}}</span></p>
                <p class="text-muted text-bold">Campaign: <span>{{detected_whitelist.campaigns}}</span></p>
                <ar-button
                    class="mt-2 align-self-end"
                    data-dismiss="modal"
                    outlined
                >
                    Close
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog
            id="assignment-error-modal"
            :has_button="false"
            :has_footer="false"
        >
            <div class="d-flex flex-column align-items-start text-left">
                <h5 style="color: #DC3C40">You are not allowed to add this listing/seller to the system for the following reasons:</h5>
                <ul class="mt-4">
                    <li>The platform does not require to contact the sellers</li>
                    <li>You are currently assigned to the following campaign/platform combination: <b>{{ currentAssignedCampaign.campaign }}/{{ currentAssignedCampaign.platform }}</b></li>
                </ul>
                <ar-button
                    class="mt-2 align-self-end"
                    data-dismiss="modal"
                >
                    Got it
                </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog
            id="deleted-listing-found-modal"
            :has_button="false"
            :has_footer="false"
        >
            <div class="d-flex flex-column align-items-center">
                <img class="position-relative" style="right: -45px;" src="/static/svg/new-snackbar/modal-warning.svg" />
                <h5 class="text-center" style="color: #DC3C40">Existing deleted listing has been found</h5>
                <p>A listing that is deleted with this listing url has been found. What do you want to do?</p>
                <div class="mt-3 mb-3 d-flex align-items-end">
                    <ar-button @click="replaceDeletedListing()" class="mr-2">
                        Replace
                    </ar-button>
                    <ar-button @click="recoverListing()">
                        Recover
                    </ar-button>
                </div>
            </div>
        </ar-dialog>

        <main class="main-con">
            <div class="splash-con">
                <img src="/static/svg/Add New Listing.svg" alt="woman looking at paypal" class="manual-add-splash mt-5">
                <ar-navlink 
                    img     ="/static/svg/View All Campaigns.svg" 
                    a_class ="manual-add-back"
                    @click  ="$router.push({name: 'campaigns'})"
                >
                        Back to Campaigns
                </ar-navlink>
            </div>
            <!-- SELLER INFORMATION YEAH -->
            <div id="sellerInfo" class="spacer"></div>
            <section>
                <h5 class="a-header">What are the details of the seller?</h5>
                <div class="row">
                    <div class="col-md-7">
                        <ar-textfield 
                            v-model.trim ="seller_info.name" 
                            id           ="seller_name" 
                            label        ="Seller Name" 
                            placeholder  ="Input Here" 
                            a_style      ="width   : 328px;" 
                            :state       ="seller_name_state"
                            :err_txt     ="seller_name_err"
                            :disabled    ="disable_seller_info"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ar-textfield 
                            id           ="seller_url"
                            v-model.trim ="seller_info.url.url"
                            placeholder  ="Input Here"
                            a_type       ="textarea"
                            a_style      ="height: 100px; resize: none;"
                            label        ="Seller URL (Press 'Ctrl + Enter' to validate)"
                            :err_txt     ="seller_info.url.err_text"
                            :state       ="seller_info.url.state"
                            :disabled    ="disable_seller_info"
                            @blur        ="checkSellerAvailability(seller_info.name, seller_info.url.url)"
                            @keyup.enter.ctrl.native ="checkSellerAvailability(seller_info.name, seller_info.url.url)"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <ar-select
                            label    ="Platform"
                            :options ="platforms"
                            v-model  ="seller_info.platform_id"
                            id       ="platform"
                        >
                            Select Platform
                        </ar-select>
                    </div>
                    <div class="col-md-6">
                        <ar-textfield
                            label       ="Site ID"
                            placeholder ="Site ID"
                            v-model     ="seller_info.site_id"
                            disabled
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <ar-banner
                            v-if        ="computed_c_listings.length && hasClientListings"
                            :heading    ="`Found ${computed_c_listings.length} added Client ${isPlural(computed_c_listings.length,'Listing')}`"
                            :text       ="`There ${computed_c_listings.length > 1 ? 'are' : 'is an'} existing ${isPlural(computed_c_listings.length,'Listing')} from Client. Please work on this first before adding new listing.`"
                            class       ="animated fadeIn faster"
                            b_class     ="bnr-warning"
                            a_class     ="btn-warning"
                            btn_text    ="Show"
                            :outlined   ="false"
                            data-toggle ="modal" 
                            data-target ="#client-listings-modal"
                        />
                        <ar-banner
                            v-if        ="found_listings.length"
                            :heading    ="`Found ${found_listings.length} added ${isPlural(found_listings.length,'Listing')}`"
                            :text       ="`Seller has already ${isPlural(found_listings.length,'listing')} in this campaign.`"
                            class       ="animated fadeIn faster"
                            b_class     ="bnr-warning"
                            a_class     ="btn-warning"
                            btn_text    ="Show"
                            :outlined   ="false"
                            data-toggle ="modal" 
                            data-target ="#list_modal"
                        />
                        <ar-banner
                            v-if      ="show_adding_seller_banner"
                            heading   ="Add New Seller"
                            text      ="Click Add to continue setting up this listing"
                            class     ="animated fadeIn faster"
                            btn_text  ="Add"
                            :outlined ="false"
                            @click    ="addNewSeller(seller_info.name.trim(), seller_info.url.url.trim())"
                        />
                        <ar-banner
                            v-if      ="show_existing_seller_banner"
                            :heading  ="existing_seller[0].name"
                            :text     ="existing_seller[0].url"
                            class     ="animated fadeIn faster"
                            btn_text  ="Select"
                            @click    ="addExistingSeller(existing_seller)"
                            :outlined ="false"
                        />
                        <ar-banner
                            v-if     ="show_already_added_seller_banner"
                            :heading ="seller_info.name"
                            class    ="animated fadeIn faster"
                            text     ="This is the seller you currently attached"
                            btn_text ="Change Seller"
                            @click   ="changeSeller"
                        />
                        <ar-banner
                            v-if    = "hasWhitelistedSeller"
                            heading = "Seller is whitelisted"
                            text    = "This seller cannot be added. Enter new seller, instead."
                            class   = "animated fadeIn faster"
                            b_class = "bnr-danger"
                            :has_btn  = "false"
                            :outlined = "false"
                        />
                    </div>
                </div>
            </section>
            <!-- END OF SELLER INFORMATION YEAH -->

            <!-- LISTING INFORMATION YEAH -->
            <template v-if="show_listing_info">
                <div class="spacer" id="listingInformation"></div>
                <section>
                    <h5 class="a-header">What are the details of this listing?</h5>
                    <div class="row">
                        <div class="col-md-12">
                            <!--
                            :err_txt    ="(listing_info.url || {}).err_text || null"
                            :state      ="(listing_info.url || {}).state || null"
                            -->
                            <ar-textfield 
                                v-model     ="listing_info.url.url"
                                label       ="Listing URL (Press 'Ctrl + Enter' to validate)"
                                a_type      ="textarea"
                                placeholder ="Input Here"
                                a_style     ="height: 100px; resize: none;"
                                :err_txt    ="listing_info.url.err_text"
                                :state      ="listing_info.url.state"
                                @input      ="show_otherlistinfo = false"
                                @blur       ="checkListing(listing_info.url.url.trim())"
                                @keyup.enter.ctrl.native ="checkListing(listing_info.url.url.trim())"
                            />
                        </div>
                    </div>
                    <div v-show="existed_listing.length > 0" class="row mb-4">
                        <div class="col-md-12">
                            <ar-list 
                                v-for         ="(list, index) in existed_listing"
                                :key          ="index"
                                :seller_name  ="list.seller"
                                :rid          ="list.id"
                                :status       ="list.status"
                                :details      ="list.title"
                                :owner        ="list.owner"
                                :has_checkbox ="false"
                                :invalid      ="true"
                            />
                        </div>
                    </div>
                    <div v-show="show_listing_details">
                        <div class="row">
                            <div class="col-md-6">
                                <ar-select
                                    v-model     ="listing_info.platform_id"
                                    :options    ="platforms"
                                    id          ="platform_id"
                                    label       ="Platform ID"
                                    placeholder ="Input Here"
                                >
                                    Please select Platform
                                </ar-select>
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    v-model     ="listing_info.listing_number"
                                    label       ="Listing Number"
                                    placeholder ="Input Here"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <ar-textfield
                                    v-model     ="listing_info.listing_title"
                                    label       ="Listing Title"
                                    a_type      ="textarea"
                                    placeholder ="Input Here"
                                    a_style     ="height: 100px; resize: none;"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <!--END OF LISTING INFORMATION-->
            <ar-modal id="list_modal">
                <template v-slot:top-btn>
                    <ar-button
                        a_class      ="a-btn-text mr-2"
                        data-dismiss ="modal"
                        icon         ="fas fa-chevron-left"
                    >
                        Back
                    </ar-button>
                </template>
                <ar-list 
                    v-for         ="(list, index) in found_listings"
                    :key          ="index"
                    :rid          ="list.id"
                    :status       ="list.status "
                    :seller_name  ="list.name"
                    :details      ="list.title"
                    :owner        ="list.researcher"
                    :qualifier    ="list.qualifier"
                    :has_checkbox ="false"
                    :product_img  ="list.preview"
                    :product_url  ="list.url"
                    :seller_url   ="list.sellerURL"
                    :platform_img ="list.platformImg"
                />
            </ar-modal>
            <ar-modal id="client-listings-modal">
                <template v-slot:top-btn>
                    <ar-button
                        a_class      ="a-btn-text mr-2"
                        data-dismiss ="modal"
                        icon         ="fas fa-chevron-left"
                    >
                        Back
                    </ar-button>
                </template>
                <ar-list 
                    v-for         ="(list, index) in computed_c_listings"
                    :key          ="index"
                    :rid          ="list.id"
                    :status       ="list.status "
                    :seller_name  ="list.name"
                    :details      ="list.title"
                    :owner        ="list.researcher"
                    :qualifier    ="list.qualifier"
                    :has_checkbox ="false"
                    :product_img  ="list.preview"
                    :product_url  ="list.url"
                    :seller_url   ="list.sellerURL"
                    :platform_img ="list.platformImg"
                    @click        ="redirect(list.id)"
                />
            </ar-modal>
        </main>

        <potential-whitelists
            :whitelists ="$store.state.potentialWhitelists"
            @click      ="acknowledgeWhitelists"
        />
    </div>
</template>

<script>
const component = view => () => import(`@/components/layouts/${view}.vue`)
import navlink from '@/components/layouts/NavigationLink'
import {isEmpty} from '@/utils/common'
// import axios from '../../../axiosMime'
import { mapActions, mapState } from 'vuex'
export default {
    name: "ArManualAddition",
    components: {
        'ar-navlink'  : navlink,//navlink,
        'ar-select'   : component("Select"),
        'ar-textfield': component("Textfield"),//textfield,
        'ar-banner'   : component("Banner"),//banner,
        'ar-button'   : component("Button"),//button,
        'ar-dialog'   : component("Dialog"),//dialog
        'ar-list'     : component("List"),
        'ar-modal'    : component("Modal"),
        'potential-whitelists': () => import('@/components/layouts/modals/PotentialWhitelists.vue')
    },
    props: {
        action_type: {
            default: "add"
        }
    },
    mounted() {
        this.getPlatforms()
    },
    computed: {
        ...mapState(["active_listing_info","is_active_list_ready",'client_Listings_manualAddition', 'currentAssignedCampaign', 'relatedCampaignBypass']),
        config() {
            return JSON.parse(JSON.parse(localStorage.getItem("ver")).headers);
        },
        importantFields() {
            return [
               this.seller_info.name,
               this.seller_info.url.url,
               this.listing_info.url.url,
               this.listing_info.listing_title
            ]
        },
        user_id() {
            return JSON.parse(localStorage.getItem('ver')).id
        },
        access_level() {
            return JSON.parse(localStorage.getItem('ver')).access_level
        },
        computed_c_listings() {
            return this.client_Listings_manualAddition
        },
    },
    data() {
        return {
            check_seller_availability_banner : true,
            show_adding_seller_banner        : false,
            show_existing_seller_banner      : false,
            show_already_added_seller_banner : false,
            seller_info: {
                seller_id : "",
                name      : "",
                url       : {
                    url      : "",
                    err_text : "",
                    state    : ""
                },
                platform_id: "",
                site_id    : "",
                contactable: 0,
            },
            platforms          : [],
            existing_seller    : [],
            disable_seller_info: false,
            input_disabled     : false,
            listing_info: {
                listing_id: "",
                url: {
                    url     : "",
                    err_text: "",
                    state   : ""
                },
                platform_id        : "",
                listing_number     : "",
                keyword            : "",
                claim_type_id      : "",
                listing_title      : "",
                item_type          : "",
                transaction_history: "",
                dummy_account      : "",
                reasons            : []
            },
            existed_listing     : {},
            show_listing_details: false,
            show_listing_info   : false,
            found_listings      : [],
            seller_name_state   : "",
            seller_name_err     : "",
            detected_whitelist  : {},
            isChecking           : false,
            isCheckingPotentials : false,
            hasAcknowledged      : false,
            acknowledgeWhitelists: () => ({}),
            hasWhitelistedSeller: false,
            hasClientListings: false,
            disabledPlatforms: [],
            deleted_listing: {}
        }
    },
    watch: {
        'seller_info.name'() {
            this.seller_name_state                = ""
            this.seller_name_err                  = ""
            this.show_adding_seller_banner        = false
            this.show_existing_seller_banner      = false
            this.check_seller_availability_banner = true
            this.hasAcknowledged                  = false
            this.hasWhitelistedSeller = false
        },
        'seller_info.url.url'() {
            this.seller_info.url.err_text         = ""
            this.seller_info.url.state            = ""
            this.show_adding_seller_banner        = false
            this.show_existing_seller_banner      = false
            this.check_seller_availability_banner = true
            this.hasWhitelistedSeller = false
        },
        'listing_info.url.url'() {
            this.existed_listing           = {}
            this.listing_info.url.err_text = ""
            this.listing_info.url.state    = ""
            this.show_listing_details      = false
        },
        'listing_info.listing_title'() {
            // if(this.listing_info.listing_title != ""){
            //     this.$store.dispatch("enablaDisabled",false)
            // }else{
            //     this.$store.dispatch("enablaDisabled",true)
            // }
        },
        importantFields(fields) {
            //trigger enabling/disabled add to listing button when all important fields are filled
            let hasEmpty = fields.some(value => value.trim() === '')
            this.$store.dispatch('enablaDisabled', hasEmpty)
        },
    },
    methods: {
        ...mapActions(['enableTabs', 'setSellerID','setRelatedCampaignDuplicates', 'setRelatedCampaignDuplicateBypass', 'setRelatedCampaignDuplicateContinueFunction']),
        showSnackbar(message, loading, type, icon) {
            this.$parent.showSnackbar(message, loading, type, icon)
        },
        async recoverListing() {
            this.$parent.showSnackbar('Trying to recover listing.. Please wait..', true)

            const config = { headers: { ...this.config.headers } };

            config.headers['Campaign-id'] = this.$route.params.campaign_id
            config.headers['One-WIP'] = this.$featureAccess.isRestricted('v-enable-one-wip') ? 'disable' : 'enable';

            try {
                const { data } = await this.$http.patch(`listings/${this.deleted_listing?.id}/recover`, {}, config)

                this.$router.push({
                    name: 'listinginfo',
                    params: { campaign_id: this.$route.params.campaign_id },
                    query: {
                        action: 'edit',
                        lid: this.deleted_listing?.id
                    }
                })

                this.showSnackbar('Successfully recovered listing!', false, 'success', 'fas fa-check-circle')
            } catch (ex) {
                this.showSnackbar(ex.message, false, 'error', 'fas fa-times-circle')
            }
        },
        async replaceDeletedListing() {
            this.$parent.showSnackbar('Trying to replace listing.. Please wait..', true)

            const config = { headers: { ...this.config.headers } };

            config.headers['Campaign-id'] = this.$route.params.campaign_id
            config.headers['One-WIP'] = this.$featureAccess.isRestricted('v-enable-one-wip') ? 'disable' : 'enable';

            try {
                let { keyword, claim_type_id, listing_title, listing_number, platform_id } = this.listing_info
                const payload = {
                    seller_id: this.seller_info.seller_id,
                    campaign_id: this.$route.params.campaign_id,
                    url: this.deleted_listing?.url,
                    platform_id,
                    keyword,
                    claim_type_id
                }
                const { data } = await this.$http.post(`listings/${this.deleted_listing?.id}/replace`, payload, config)

                this.$router.push({
                    name: 'listinginfo',
                    params: { campaign_id: payload.campaign_id },
                    query: {
                        action: 'edit',
                        lid: data.data?.id
                    }
                })

                this.showSnackbar('Successfully replaced listing!', false, 'success', 'fas fa-check-circle')
            } catch (ex) {
                this.showSnackbar(ex.message, false, 'error', 'fas fa-times-circle')
            }
        },
        /**
         * @param {string} name
         * @param {string} url
         * @return {Promise<boolean|any>}
         */
        async checkPotentialWhitelists(name, url) {
            if(this.isCheckingPotentials) return
            this.isCheckingPotentials = true
            const {success, data, message} = await this.$store.dispatch('checkPotentialWhitelists', [{name, url}])
            if(!success) {
                this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                return false
            }
            if(data.length === 0) return false
            /** Check if there is full whitelisted seller from potential whitelist sellers */
            this.hasWhitelistedSeller = data[0].potentials.some(item => item.is_whitelist)
            await this.$store.dispatch('setPotentialWhitelists', data)
            $('#potential-whitelists').modal('show')
            this.acknowledgeWhitelists = async () => {
                if(!this.hasWhitelistedSeller) {
                    const acknowledgement = {
                        feature: 'manual addition',
                        status : 1,
                        notes  : ''
                    }
                    await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)
                }
                
                this.closeModal()
                if(this.hasWhitelistedSeller) {
                    this.showSnackbar('Seller is whitelisted. This seller cannot be added.', false, 'invalid', 'fas fa-times-circle')
                }
                if(!this.hasWhitelistedSeller) {
                    this.hasAcknowledged = true
                    this.checkSellerAvailability(name, url)
                }
            }
            return true
        },
        /**
         * @param {string} name
         * @param {string} url
         * @return {boolean}
         */
        validateSellerInput(name, url) {
            url = url.replace(/ /g, '')
            this.show_adding_seller_banner   = false
            this.show_existing_seller_banner = false
            this.found_listings              = []
            this.seller_info.url.url         = url
            // if(name === '' || url === '') {
            if(isEmpty(name) || isEmpty(url)) {
                if(isEmpty(name)) {
                    this.seller_name_state = 'invalid'
                    this.seller_name_err   = 'Seller Name is required'
                } else {
                    this.seller_info.url.state    = 'invalid'
                    this.seller_info.url.err_text = 'Seller URL is required'
                }
                this.showSnackbar('Please provide Seller URL and Seller Name', false,'invalid','fas fa-times-circle')
                return false
            }
            if(!this.isValidUrl(url)) {
                this.seller_info.url.state    = 'invalid'
                this.seller_info.url.err_text = 'Invalid Seller URL'
                this.showSnackbar('Invalid Seller URL. If you think this is wrong, please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                return false
            }
            return true
        },
        async getClientListings_fromVueX() {
            let gui = 'ver'
            let payload = {
                type: this.access_level == 5 ? 'byCampaignLead' : '',
                gui: gui,
                user_id: this.user_id,
            }
            await this.$store.dispatch('setListing_has_clientListings', payload)
        },
        redirect(lid = '') {
            this.hideClientListings()
            this.$store.dispatch('setKeywords', lid)
            this.$router.push({
                name: 'listing',
                params: {
                    campaign_id: this.$route.params.campaign_id
                },
                query: {
                    status: 'Client Listing',
                    platform: 0,
                }
            })
        },
        async checkSellerAvailability(name, url) {
            if(this.isChecking) return
            const isOkay = this.validateSellerInput(name, url)
            if(!isOkay) {
                this.isChecking = false
                return
            }
            this.isChecking = true
            this.showSnackbar('Checking seller availability.. Please wait..', true)

            const config = { headers: { ...this.config.headers } };
            config.headers['Campaign-id'] = this.$route.params.campaign_id

            const encodedName = encodeURIComponent(name)
            const encodedUrl  = encodeURIComponent(url)
            /* if(!this.hasAcknowledged) {
                const hasPotentials = await this.checkPotentialWhitelists(name, url)
                this.isCheckingPotentials = false
                if(hasPotentials) {
                    this.$parent.hideSnackbar()
                    this.isChecking = false
                    return
                }
            } */

            // CHECK IF THE PLATFORM IS ENABLED IN MANUAL ADDITION START

            const disableOneWip              = this.$featureAccess.isRestricted('v-enable-one-wip');
            const disableBlockManualAddition = this.$featureAccess.isRestricted('v-enable-block-manual-addition');

            config.headers['One-WIP']               = disableOneWip ? 'disable' : 'enable';
            config.headers['Block-Manual-Addition'] = disableBlockManualAddition ? 'disable': 'enable';

            this.$http.get(`/fma_fac/check?url=${encodedUrl}&type=manual_addition&campaign_id=${this.$route.params.campaign_id}`, config).then(response => {
                if(response?.status === 200) {

                    config.headers['Auto-Assignment'] = this.$featureAccess.isRestricted('v-enable-auto-assignment') ? 'disable' : 'enable';

                    this.$http.get(`/sellers/check?name=${encodedName}&enable-assignment-restriction=1&url=${encodedUrl}`, config)
                        .then(async (response) => { 
                            this.setRelatedCampaignDuplicateBypass(false)
                            $('#related-campaign-duplicate-sellers-modal').modal('hide')

                            const results = response.data   
                            //Alert user when seller is whitelisted
                            if(results.meta.whitelisted) {
                                if(this.$route.name === 'manualaddition') {
                                    this.detected_whitelist = {
                                        name     : results.data.seller_name,
                                        url      : results.data.seller_url,
                                        site_id  : results.data.site_id,
                                        platforms: results.data.platforms.join(', '),
                                        campaigns: results.data.campaigns .join(', ')
                                    }
                                    $('#whitelist-info-modal').modal('show')
                                    return this.showSnackbar('Seller is whitelisted', false, 'invalid', 'fas fa-times-circle')
                                }
                            }
                            //Found existing seller
                            if(+results.meta.code === 200) {
                                //Seller is already added but you can still add this
                                const contactable  = +results.data[0].contactable
                                const getPlatform  = id => this.platforms.find(platform => platform.value == id)?.label.split(' - ').shift() || ''
                                //Get qualified items
                                const hasQualifiedItem = results.data.map(q => +q.qualified_items).some(q => +q > 0)
                                if(hasQualifiedItem) {
                                    //Get qualified listing
                                    const qualifiedListing = results.data.find(ql => ql.qualified_items > 0)?.listings || []
                                    this.found_listings = qualifiedListing.map(list => ({
                                        id         : list.id,
                                        status     : list.status,
                                        name       : list.seller[0].name,
                                        url        : list.url,
                                        title      : list.listing_title,
                                        researcher : `${list.verifier_owner.first_name} ${list.verifier_owner.last_name}`,
                                        preview    : list.evidences.preview || list.evidences.Preview,
                                        qualifier  : list.qualifier,
                                        sellerURL  : list.seller[0].url,
                                        platformImg: this.$parent.getImage('platform', getPlatform(list.platform_id))
                                    }))
                                    this.$store.dispatch('allowSaveExit', true)
                                    this.showSnackbar('Seller is already worked by another researcher. Please add different seller.', false, 'invalid', 'fas fa-store')
                                } else {
                                    this.existing_seller = [{
                                        name       : results.data[0]?.name,
                                        url        : results.data[0]?.url,
                                        platform_id: results.data[0]?.platform_id,
                                        site_id    : results.data[0]?.site_id,
                                        contactable: contactable,
                                        seller_id  : results.data[0]?.id
                                    }]
                                    if(results.data[0].listings.length > 0) {
                                        this.found_listings = results.data[0].listings
                                            // .filter(list => ![99, 33].includes(+list.qflag_id) ) Exclude invalid and rejected from duplicate checking
                                            .map(list =>
                                                ({
                                                    id         : list.id,
                                                    status     : list.status,
                                                    url        : list.url,
                                                    name       : list.seller[0].name,
                                                    title      : list.listing_title,
                                                    researcher : `${list.verifier_owner.first_name} ${list.verifier_owner.last_name}`,
                                                    preview    : list.evidences.preview || list.evidences.Preview,
                                                    qualifier  : list.qualifier,
                                                    sellerURL  : list.seller[0].url,
                                                    platformImg: this.$parent.getImage('platform', getPlatform(list.platform_id))
                                                })
                                            )
                                    }
                                    this.show_existing_seller_banner = true
                                    this.showSnackbar('An existing seller is found in our system', false, 'info', 'fas fa-store')
                                    this.check_seller_availability_banner = false
                                    // this.seller_info.url["url"]        = results.meta.parsed_url
                                    this.seller_info.site_id              = results.meta.site_id
                                    this.seller_info.platform_id          = results.meta.platform_id
                                    this.seller_info.contactable          = +![10,94,152,234,236,238,240,242,166,370].includes(results.meta.platform_id)
                                    // this.seller_info.contactable       = [10,94,152,234,236,238,240,242,166,370].includes(results.meta.platform_id) ? 0 : 1
                                    document.getElementById('platform').value = results.meta.platform_id
                                }
                            } else if(+results.meta.code === 202) {//No seller existing seller found
                                if(!this.hasAcknowledged) {
                                    const hasPotentials = await this.checkPotentialWhitelists(name, url)
                                    this.isCheckingPotentials = false
                                    if(hasPotentials) {
                                        this.$parent.hideSnackbar()
                                        this.isChecking = false
                                        return
                                    }
                                }
                                this.show_adding_seller_banner = true
                                this.showSnackbar('Seller checking done! No existing seller found', false, 'success', 'fas fa-check')
                                this.check_seller_availability_banner = false
                                // this.seller_info.url["url"]        = results.meta.parsed_url
                                this.seller_info.site_id              = results.meta.site_id
                                this.seller_info.platform_id          = results.meta.platform_id != null ? results.meta.platform_id : 120
                                this.seller_info.contactable          = [10, 94, 152, 234, 236, 238, 240, 242, 166, 370].includes(results.meta.platform_id) ? 0 : 1
                                document.getElementById('platform').value = this.seller_info.platform_id
                            } else if(+results.meta.code === 400) {
                                this.showSnackbar('The seller you are trying to add is whitelisted.', false, 'invalid', 'fas fa-times-circle')
                            }
                        })
                        .catch(async err => {
                            const duplicate_logs = err?.response?.data?.duplicate_logs
                            if (duplicate_logs) {
                                this.setRelatedCampaignDuplicateContinueFunction(() => this.checkSellerAvailability(name, url))
                                this.setRelatedCampaignDuplicates(duplicate_logs)
                                this.showSnackbar(`${err.response.data.message}`,false,'invalid','fas fa-times-circle')
                                return    
                            }

                            if (err?.response?.data?.meta?.code === 403) {
                                $("#assignment-error-modal").modal("show");
                                this.showSnackbar(`You are not allowed to add this seller`,false,'invalid','fas fa-times-circle')
                                return;
                            }

                            console.error({err})
                            await this.getClientListings_fromVueX()
                            const getPlatform  = id => this.platforms.find(platform => platform.value == id)?.label.split(' - ').shift() || ''

                            // const message = err.response.data.meta.message.includes('The user already has Work in Progress') || err.response.data.meta.message.includes('There is an existing Listing from Client.') ? err.response.data.meta.message : 'Something went wrong. Please contact system admin.'
                            this.showSnackbar(err?.response?.data?.meta?.message ?? 'Something went wrong on our end. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                            console.log(err.response.data.meta.message)
                            if(err.response.data.meta.message.includes('There is an existing Listing from Client.')) {
                                
                                this.hasClientListings = err.response.data.meta.existingLFC
                                
                                for(let i=0;i<this.client_Listings_manualAddition.length;i++){
                                    this.client_Listings_manualAddition[i].platformImg = await this.$parent.getImage('platforms', getPlatform(this.client_Listings_manualAddition[i].platform_id))
                                }
                                this.showClientListings()
                            } else {
                                this.hasClientListings = false
                            }
                            this.showSnackbar(message,false,'invalid','fas fa-times-circle')
                        })
                        .finally(() => this.isChecking = false)
                }
            }).catch(err => {
                let error = {err}
                if(error?.err?.response?.data?.success == false){
                    const duplicate_logs = error?.err?.response?.data?.duplicate_logs
                    if (duplicate_logs) {
                        this.setRelatedCampaignDuplicateContinueFunction(() => this.checkSellerAvailability(name, url))
                        this.setRelatedCampaignDuplicates(duplicate_logs)
                        this.showSnackbar(`${error.err.response.data.message}`,false,'invalid','fas fa-times-circle')
                        return    
                    }
                    if(err?.response?.data?.both_disabled) {
                        this.showSnackbar('Platform is blocked in both Manual Addition and Auto-Capture Request', false, 'invalid', 'fas fa-times-circle')
                        return
                    }
                    else {
                        this.showSnackbar(`${err?.response?.data?.message} Please add listing in Auto Capture.`, false, 'invalid', 'fas fa-times-circle')
                        setTimeout(() => {
                            setTimeout(() => {
                                        this.showSnackbar(`Redirecting to Auto capture request...`,true)
                                    }, 3000)
                                    setTimeout(() => {
                                        this.$router.push({name: 'auto-capture-listing'})
                                    },5000)
                        })
                        return
                    }
                }
                this.showSnackbar(`${error.err.response.data.meta.message}`,false,'invalid','fas fa-times-circle')
            })
            .finally(() => this.isChecking = false)
            //CHECK IF THE PLATFORM IS ENABLED IN MANUAL ADDITION END
            

            // this.$http.get(`/sellers/check?name=${encodedName}&enable-assignment-restriction=1&url=${encodedUrl}`, config)
            //     .then(async (response) => {
            //         const results = response.data   
            //         //Alert user when seller is whitelisted
            //         if(results.meta.whitelisted) {
            //             if(this.$route.name === 'manualaddition') {
            //                 this.detected_whitelist = {
            //                     name     : results.data.seller_name,
            //                     url      : results.data.seller_url,
            //                     site_id  : results.data.site_id,
            //                     platforms: results.data.platforms.join(', '),
            //                     campaigns: results.data.campaigns .join(', ')
            //                 }
            //                 $('#whitelist-info-modal').modal('show')
            //                 return this.showSnackbar('Seller is whitelisted', false, 'invalid', 'fas fa-times-circle')
            //             }
            //         }
            //         //Found existing seller
            //         if(+results.meta.code === 200) {
            //             //Seller is already added but you can still add this
            //             const contactable  = +results.data[0].contactable
            //             const getPlatform  = id => this.platforms.find(platform => platform.value == id)?.label.split(' - ').shift() || ''
            //             //Get qualified items
            //             const hasQualifiedItem = results.data.map(q => +q.qualified_items).some(q => +q > 0)
            //             if(hasQualifiedItem) {
            //                 //Get qualified listing
            //                 const qualifiedListing = results.data.find(ql => ql.qualified_items > 0)?.listings || []
            //                 this.found_listings = qualifiedListing.map(list => ({
            //                     id         : list.id,
            //                     status     : list.status,
            //                     name       : list.seller[0].name,
            //                     url        : list.url,
            //                     title      : list.listing_title,
            //                     researcher : `${list.verifier_owner.first_name} ${list.verifier_owner.last_name}`,
            //                     preview    : list.evidences.preview || list.evidences.Preview,
            //                     qualifier  : list.qualifier,
            //                     sellerURL  : list.seller[0].url,
            //                     platformImg: this.$parent.getImage('platform', getPlatform(list.platform_id))
            //                 }))
            //                 this.$store.dispatch('allowSaveExit', true)
            //                 this.showSnackbar('Seller is already worked by another researcher. Please add different seller.', false, 'invalid', 'fas fa-store')
            //             } else {
            //                 this.existing_seller = [{
            //                     name       : results.data[0]?.name,
            //                     url        : results.data[0]?.url,
            //                     platform_id: results.data[0]?.platform_id,
            //                     site_id    : results.data[0]?.site_id,
            //                     contactable: contactable,
            //                     seller_id  : results.data[0]?.id
            //                 }]
            //                 if(results.data[0].listings.length > 0) {
            //                     this.found_listings = results.data[0].listings
            //                         // .filter(list => ![99, 33].includes(+list.qflag_id) ) Exclude invalid and rejected from duplicate checking
            //                         .map(list =>
            //                             ({
            //                                 id         : list.id,
            //                                 status     : list.status,
            //                                 url        : list.url,
            //                                 name       : list.seller[0].name,
            //                                 title      : list.listing_title,
            //                                 researcher : `${list.verifier_owner.first_name} ${list.verifier_owner.last_name}`,
            //                                 preview    : list.evidences.preview || list.evidences.Preview,
            //                                 qualifier  : list.qualifier,
            //                                 sellerURL  : list.seller[0].url,
            //                                 platformImg: this.$parent.getImage('platform', getPlatform(list.platform_id))
            //                             })
            //                         )
            //                 }
            //                 this.show_existing_seller_banner = true
            //                 this.showSnackbar('An existing seller is found in our system', false, 'info', 'fas fa-store')
            //                 this.check_seller_availability_banner = false
            //                 // this.seller_info.url["url"]        = results.meta.parsed_url
            //                 this.seller_info.site_id              = results.meta.site_id
            //                 this.seller_info.platform_id          = results.meta.platform_id
            //                 this.seller_info.contactable          = +![10,94,152,234,236,238,240,242,166,370].includes(results.meta.platform_id)
            //                 // this.seller_info.contactable       = [10,94,152,234,236,238,240,242,166,370].includes(results.meta.platform_id) ? 0 : 1
            //                 document.getElementById('platform').value = results.meta.platform_id
            //             }
            //         } else if(+results.meta.code === 202) {//No seller existing seller found
            //             if(!this.hasAcknowledged) {
            //                 const hasPotentials = await this.checkPotentialWhitelists(name, url)
            //                 this.isCheckingPotentials = false
            //                 if(hasPotentials) {
            //                     this.$parent.hideSnackbar()
            //                     this.isChecking = false
            //                     return
            //                 }
            //             }
            //             this.show_adding_seller_banner = true
            //             this.showSnackbar('Seller checking done! No existing seller found', false, 'success', 'fas fa-check')
            //             this.check_seller_availability_banner = false
            //             // this.seller_info.url["url"]        = results.meta.parsed_url
            //             this.seller_info.site_id              = results.meta.site_id
            //             this.seller_info.platform_id          = results.meta.platform_id != null ? results.meta.platform_id : 120
            //             this.seller_info.contactable          = [10, 94, 152, 234, 236, 238, 240, 242, 166, 370].includes(results.meta.platform_id) ? 0 : 1
            //             document.getElementById('platform').value = this.seller_info.platform_id
            //         } else if(+results.meta.code === 400) {
            //             this.showSnackbar('The seller you are trying to add is whitelisted.', false, 'invalid', 'fas fa-times-circle')
            //         }
            //     })
            //     .catch(async err => {
            //         if (err?.response?.data?.meta?.code === 403) {
            //             $("#assignment-error-modal").modal("show");
            //             this.showSnackbar(`You are not allowed to add this seller`,false,'invalid','fas fa-times-circle')
            //             return;
            //         }

            //         console.error({err})
            //         await this.getClientListings_fromVueX()
            //         const getPlatform  = id => this.platforms.find(platform => platform.value == id)?.label.split(' - ').shift() || ''

            //         // const message = err.response.data.meta.message.includes('The user already has Work in Progress') || err.response.data.meta.message.includes('There is an existing Listing from Client.') ? err.response.data.meta.message : 'Something went wrong. Please contact system admin.'
            //         this.showSnackbar(err?.response?.data?.meta?.message ?? 'Something went wrong on our end. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
            //         console.log(err.response.data.meta.message)
            //         if(err.response.data.meta.message.includes('There is an existing Listing from Client.')) {
                        
            //             this.hasClientListings = err.response.data.meta.existingLFC
                        
            //             for(let i=0;i<this.client_Listings_manualAddition.length;i++){
            //                 this.client_Listings_manualAddition[i].platformImg = await this.$parent.getImage('platforms', getPlatform(this.client_Listings_manualAddition[i].platform_id))
            //             }
            //             this.showClientListings()
            //         } else {
            //             this.hasClientListings = false
            //         }
            //         this.showSnackbar(message,false,'invalid','fas fa-times-circle')
            //     })
            //     .finally(() => this.isChecking = false)
        },
        addNewSeller(name, url) {
            const campaign_id = this.$route.params.campaign_id
            const platform_id = this.seller_info.platform_id
            const site_id     = this.seller_info.site_id
            const contactable = this.seller_info.contactable
            let config = this.config
            config.headers['Campaign-id'] = this.$route.params.campaign_id
            this.showSnackbar('Adding new seller.. Please wait..', true)
            if(typeof campaign_id !== "undefined" && +campaign_id !== 0) {
                const data = {
                    campaign_id,
                    platform_id,
                    name,
                    site_id,
                    url,
                    contactable
                }

                config.headers['Auto-Assignment'] = this.$featureAccess.isRestricted('v-enable-auto-assignment') ? 'disable' : 'enable';

                // axios.post(process.env.VUE_APP_URL+"/sellers",{campaign_id,platform_id, name, site_id, "url":url.trim(), contactable},config)
                this.$http.post('/sellers', data, config)
                    .then(response => {
                        let results = response.data
                        //Seller is successfully added
                        if(+results.meta.code === 201) {
                            this.seller_info.seller_id            = results.data.id
                            this.show_adding_seller_banner        = false
                            this.show_already_added_seller_banner = true
                            this.disable_seller_info              = true//For disabled Seller Info Fields
                            this.show_listing_info                = true
                            this.setSellerID(results.data.id)
                            this.showSnackbar('New seller successfully added', false, 'success', 'fas fa-check')
                        }                       
                    })
                    .catch(err => {
                        if (err?.response?.data?.meta?.code === 403) {
                            $("#assignment-error-modal").modal("show");
                            this.showSnackbar(`You are not allowed to add this seller`,false,'invalid','fas fa-times-circle')
                            return;
                        }

                        console.error(err)
                        const message = `${err.response.data.meta.message}. Please contact system admin.`
                        this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                    })
            }
        },
        addExistingSeller(existing_seller) {
            this.showSnackbar('Attaching existing seller.. Please wait..', true)
            this.show_existing_seller_banner = false
            for(let seller of existing_seller) {
                this.seller_info.seller_id = seller.seller_id
                this.seller_info.name      = seller.name
                this.seller_info.url.url   = seller.url
                this.seller_info.site_id   = seller.site_id
                this.setSellerID(seller.seller_id)
                document.getElementById('platform').value = seller.platform_id
            }
            setTimeout(_ => {
                this.check_seller_availability_banner = false
            },1)
            this.show_already_added_seller_banner = true
            this.disable_seller_info              = true
            this.show_listing_info                = true
            this.showSnackbar('Existing seller successfully attached', false, 'success', 'fas fa-check')
        },
        changeSeller() {
            this.show_already_added_seller_banner = false
            this.disable_seller_info              = false //for enabling seller info fields
            this.check_seller_availability_banner = true
            this.show_listing_info                = false
        },
        isValidUrl(string) {
            /* const is_valid = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
            return (is_valid !== null) */
            try {
                new URL(string);
                return true;
            } catch (_) {
                return false;
            }
        },
        getPlatforms() {
            let config = this.config
            config.headers.Where = JSON.stringify({status: 1})
            // axios.get(process.env.VUE_APP_URL+"/platforms?page_size=1000", config)
            this.$http.get('/platforms?page_size=1000', config)
                .then(response => {
                    let results = response.data
                    this.platforms = results.data.map(item => ({
                        label: `${item.name} - (${item.url})`,
                        value: item.id,
                        for_auto_capture: item.for_auto_capture
                    }))
                    this.disabledPlatforms = results.data.filter(item => item.for_auto_capture == 1).map(i => ({
                        value: i.id
                    }))
                    /*this.platforms = results.data.map(platform=>{
                        return {value:platform.id,label:`${platform.name} (${platform.url})`}
                    })*/
                })
                .catch(err => {
                    console.error(err)
                    const message = `${err.response.data.meta.message}. Please contact system admin.`
                    this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                })
        },
        checkListing(url) {
            this.existed_listing = {}
            //const campaign_id = this.$route.params.campaign_id
            let config        = this.config
            config.headers['seller-id'] = this.seller_info.seller_id;
            config.headers['Campaign-id'] = this.$route.params.campaign_id
            config.headers['Auto-Assignment'] = this.$featureAccess.isRestricted('v-enable-auto-assignment') ? 'disable' : 'enable';
            this.showSnackbar('Checking Listing...')
            if(url === '') {
                this.listing_info.url.err_text = 'Listing URL is required'
                this.listing_info.url.state    = 'invalid'
                return this.showSnackbar('Listing URL is required', false, 'invalid', 'fas fa-times-circle')
            }
            // if(url.trim() != "") {
                if(!this.isValidUrl(url)) {
                    this.listing_info.url.err_text = 'Invalid URL Format'
                    this.listing_info.url.state    = 'invalid'
                    const message = 'Invalid Listing URL. If you think this is wrong, please contact System Admin'
                    return this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                }
                // if(this.isValidUrl(url)) {
                    //axios.get(process.env.VUE_APP_URL+`/listings/check?url=${encodeURIComponent(url)}`,config)
                    this.$http.get(`/listings/check?enable-assignment-restriction=1&url=${encodeURIComponent(url)}`, config)
                        .then(response => {
                            let results = response.data
                            if(+results.meta.code === 200) {
                                // console.log("Listing is already existed")
                                this.existed_listing = results.data.map(listing => ({
                                    id    : listing.id,
                                    title : listing.listing_info.listing_title,
                                    status: listing.status,
                                    owner : listing.verifier_owner !== null
                                        ? `${listing.verifier_owner.first_name} ${listing.verifier_owner.last_name}`
                                        : 'No owner found',
                                    seller: listing.seller[0] !== undefined
                                        ? listing.seller[0].name
                                        : `No Seller ${name}`,
                                    // owner : listing.verifier_owner != null ? listing.verifier_owner.first_name+" "+listing.verifier_owner.last_name : "No owner found"
                                }))
                                this.listing_info.listing_title = ''
                                this.listing_info.url.err_text  = 'Listing is already added in this campaign'
                                this.listing_info.url.state     = 'invalid'
                                this.showSnackbar('Oops, the listing is already added in the database.', false, 'invalid', 'fas fa-times-circle')
                            }
                            if(+results.meta.code === 202) {
                                this.show_listing_details        = true
                                // this.listing_info.url["url"] = results.meta.parsed_url
                                this.listing_info.url.err_text   = 'Listing is ready to be added'
                                this.listing_info.url.state      = 'success'
                                this.listing_info.listing_number = results.meta.listing_number
                                this.listing_info.platform_id    = results.meta.platform_id
                                setTimeout(_ => {
                                    document.getElementById("platform_id").value = results.meta.platform_id
                                }, 1000);
                                this.showSnackbar('Great! This listing can now be added!', false, 'success', 'fas fa-check')
                                // console.log("Listing is okay to be added")
                            }
                        })
                        .catch(err => {
                            if (err?.response?.data?.meta?.code === 403) {
                                $("#assignment-error-modal").modal("show");
                                this.showSnackbar(`You are not allowed to add this listing`,false,'invalid','fas fa-times-circle')
                                return;
                            }

                            console.error(err)
                            const message = `${err.response.data.meta.message}. Please contact system admin.`
                            this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                        })
                // } else {
                //     this.showSnackbar('Invalid URL Format.. Please try again..', false, 'invalid', 'fas fa-times-circle')
                //     this.listing_info.url.err_text = "Invalid URL Format"
                //     this.listing_info.url.state   = "invalid"
                // }
            // }
        },
        addListing() {
            let campaign_id     = this.$route.params.campaign_id
            let seller_id       = this.seller_info.seller_id
            let url             = this.listing_info.url.url.trim()
            let seller_platform = this.seller_info.platform_id
            let site_id         = this.seller_info.site_id
            let hasEmpty = [this.seller_info.name, this.seller_info.url.url, this.listing_info.url.url, this.listing_info.listing_title]
                .some(item => item === '')
            if(hasEmpty) {
                return this.showSnackbar(`Please don't leave fields empty`, false, 'invalid', 'fas fa-times-circle')
            }
            //if (this.seller_info.name != "" && this.seller_info.url.url != "" && this.listing_info.url.url != "" && this.listing_info.listing_title != "") {
                // let { keyword, claim_type_id, listing_title, item_type, transaction_history, dummy_account, reasons, initial_price, maximum_price, currency, unit, payment_type, logo_on_item_image, brand_on_item_image, is_purchase, payment_platform, transaction_number, purchase_price, listing_location, shipping_location} = this.listing_info
                this.showSnackbar('Saving new listing.. Please wait..', true);

                const config = { headers: { ...this.config.headers } };

                config.headers['Campaign-id'] = this.$route.params.campaign_id
                config.headers['One-WIP'] = this.$featureAccess.isRestricted('v-enable-one-wip') ? 'disable' : 'enable';

                let { keyword, claim_type_id, listing_title, listing_number, platform_id } = this.listing_info
                const data = {
                    seller_id,
                    campaign_id,
                    url,
                    platform_id,
                    keyword,
                    claim_type_id
                }

                //axios.post(process.env.VUE_APP_URL+"/my/listings", data, config)
                this.$http.post('/my/listings', data, config)
                    .then(response => {
                        let results = response.data
                        if(+results.meta.code !== 201) {
                            const message = `${results.meta.message} ${results.meta.additional_message}`
                            return this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                        }
                        //if(+results.meta.code === 201) {
                            let listing_id   = results.data.id
                            let listing_body = {
                                listing_id,
                                listing_title,
                                platform_id
                            }
                            // let listing_body = {listing_id,listing_title, item_type, transaction_history, dummy_account, "reasons":reasons, initial_price, maximum_price, currency, unit, payment_type, logo_on_item_image, brand_on_item_image, is_purchase, payment_platform, transaction_number, purchase_price, listing_location, shipping_location}
                            //axios.post(process.env.VUE_APP_URL+"/listing_info", listing_body, config)
                            this.$http.post('/listing_info', listing_body, config)
                                .then(response => {
                                    let results = response.data
                                    if(+results.meta.code === 201) {
                                        this.showSnackbar('Well done, New listing has been added!', false, 'success', 'fas fa-check')
                                        this.$store.dispatch('enablaDisabled',true)
                                        setTimeout(_ => {
                                            this.closeModal()
                                            // this.$router.push({name:"listing",params:{campaign_id},query:{status: "Work in Progress", platform: "0"}})
                                            this.$router.push({
                                                name: 'listinginfo',
                                                params: { campaign_id },
                                                query: {
                                                    action: 'edit',
                                                    lid: listing_id
                                                }
                                            })
                                        }, 1000);
                                    }
                                })
                                .catch(err => {
                                    console.error(err)
                                    this.showSnackbar(err?.response?.data?.meta?.message ?? 'Something went wrong on our end. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                                })
                        /*}else{
                            const message = `${results.meta.message} ${results.meta.additional_message}`
                            this.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                        }*/
                    })
                    .catch(async err => {
                        if (err.response.data.meta.code == 409) {
                            this.showSnackbar(err?.response?.data?.meta?.message, false, 'warning', 'fas fa-exclamation-triangle')
                            $('.modal').modal('hide')

                            await sleep(100)

                            this.deleted_listing = err.response.data.meta.listing
                            return $('#deleted-listing-found-modal').modal('show')
                        }

                        console.error(err)
                        this.showSnackbar(err?.response?.data?.meta?.message ?? 'Something went wrong on our end. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                    })
            /*} else {
                this.$parent.showSnackbar("Please don't leave fields empty.", false, 'invalid', 'fas fa-times-circle')
            }*/
        },
        closeModal() {
            /*document.querySelector('.modal.fade').classList.add('animated', 'fadeOut', 'fast')
            document.querySelector('.modal-backdrop.fade.show').classList.add('animated', 'fadeOut', 'fast')
            setTimeout(() => {
                document.body.removeChild(document.querySelector('.modal-backdrop.fade.show'))
                document.querySelector('.modal-open').classList.remove('modal-open')
                document.querySelectorAll('.modal').forEach(modal => {
                    modal.classList.remove('show')
                    modal.style.paddingRight = ""
                    modal.style.display = ""
                })
                setTimeout(() => {
                    document.querySelector('.modal.fade').classList.remove('animated', 'fadeOut', 'fast')
                    document.body.click()
                })
            })*/
            $('.modal').modal('hide')
        },
        isPlural(value, text){
            /*if(value > 1)
                return text+'s'
            return text*/
            return value > 1 ? `${text}s` : text
        },
        showClientListings() {
            $('#client-listings-modal').modal('show')
        },
        hideClientListings() {
            $('#client-listings-modal').modal('hide')
        },
    }
}
</script>
<style>
    .a-container {
        width     : 100%;
        position  : relative;
        min-height: 100vh;
        background: #ffff;
        /* padding-top: 109px; */
    }
    .a-navcon {
        position: fixed;
        top     : 0;
        right   : 0;
        left    : 0;
        z-index : 5;
    }
    .a-container .a-navcon .a-navbar {
        z-index: 2;
    }
    .splash-con {
        width     : 547px;
        padding   : 48px 30px;
        position  : fixed;
        height    : 100vh;
        right     : 0;
    }
    .manualAddition .main-con {
        position: relative;
        padding : 30px 689px 150px 70px;
    }
    .a-header {
        letter-spacing: 0.024em;
        color         : #515365;
        font-weight   : 500;
        font-size     : 24px;
        margin-bottom : 32px;
    }
    .a-header span {
        display    : block;
        font-size  : 16px;
        font-weight: 400;
        color      : #383A3D;
        margin-top : 8px;
    }
    .section-label {
        color      : #383A3D;
        margin-left: 5px;
        font-weight: 500 !important;
        font-size  : 13px;
    }
    .spacer {
        min-height: 75px;
    }
    section .a-checkbox { 
        margin-bottom: 20px;        
    }
    .manual-add-back {
        position: absolute;
        bottom: 70px;
        right: 40px;
        z-index: 2;
    }
    .manual-add-splash {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        right: 142px;
        width: 77.5%;
        top: 30.7%;
    }
</style>
